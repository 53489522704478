import React, { useState, } from 'react';
import MUIDataTable from 'mui-datatables';
import CustomToolbar from './CustomToolbar';
import SumCard from './SumCard';
import '../../../src/css/general.css'

import {
  createMuiTheme,
  MuiThemeProvider
} from '@material-ui/core/styles';

const SmartTable = props => {
  const { data, columns, title, csvName, selectableRows, totalRow, customBarBull = false, action, actionFun, add_options = {}, addTotalRow = true, addStyleObj = {}, actionOnRows, colName } = props;
  const [length, setLength] = useState(data.length);
  const [summ, setSumm] = useState([]);
  const [columnsName, setColumnsName] = useState('');

  const FormatILS = (value) => {
    const nf = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'ILS',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    return nf.format(value);
  }



  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          root: {
            textAlign: 'right',
          },
          caption: {
            left: '0px',
            display: 'none'
          },
          paper: {
            textAlign: 'right',
            height: 'inherit',
          },
          responsiveScroll: {
            textAlign: 'right',
            maxHeight: 'none',
            height: '100%'
          }
        },
        MUIDataTableBodyCell: {
          root: {
            // textAlign:'right',
            // minWidth: '150px',
            // maxWidth: '620px',
            // overflow: 'scroll',
          }
        },
        // MUIDataTableToolbar: {
        //   root: {
        //     textAlign:'left',
        //   }
        // },
        MUIDataTableDivider: {
          root: {
            backgroundColor: '#1D252D',
            color: 'rgb(255, 255, 255)'
          }
        },
        MUIDataTableToolbarSelect: {
          root: {
            backgroundColor: '#BBDEFB',
            textAlign: 'right',
            padding: '15px'
          },
        },
        ...addStyleObj
      }
    });
  var customBar = {
    customToolbar: () => {
      return <CustomToolbar action={action} addRow={customBarBull ? () => actionFun() : ''} />;
    }
  }
  const toFixed = (x) => {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split('e-')[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split('+')[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += (new Array(e + 1)).join('0');
      }
    }
    return x;
  }
  const options = {
    ...add_options,
    selectableRows: !selectableRows ? false : 'multiple',
    responsive: 'scroll',
    filterType: 'multiselect',
    rowsPerPageOptions: [50, 100, 200, 500],
    rowsPerPage: 100,
    onDownload: (buildHead, buildBody, columns, data) => {
      return '\uFEFF' + buildHead(columns) + buildBody(data);
    },
    downloadOptions: { filename: `${csvName}.csv`, separator: ',' },
    onTableInit: (action, tableState) => {
      if (colName != '' && colName != undefined) {
        let colname = [];
        tableState.columns.map((y, index_loop) => {
          colname.push({ name: y.name, index: index_loop })
        })
        setColumnsName(colname);
        colName(colname)
      }
      totalRow && claclTotal(tableState)
    },
    onTableChange: (action, tableState) => {
      if (addTotalRow) {
        if (actionOnRows) {
          props.actionOnProduct(tableState);
        }
        if (totalRow) {
          claclTotal(tableState)
        }
      }
    },
    textLabels: {
      body: {
        noMatch: 'לא נמצאו תוצאות מתאימות',
        toolTip: 'סנן',
        columnHeaderTooltip: column => `סדר לפי ${column.label}`
      },
      pagination: {
        next: 'דף הבא',
        previous: 'דף הקודם',
        rowsPerPage: 'שורות פר עמוד:',
        displayRows: 'מתוך',
      },
      toolbar: {
        search: 'חיפוש',
        downloadCsv: 'הורדת קובץ csv',
        print: 'הדפס',
        viewColumns: 'צפה בעמודות',
        filterTable: 'סנן טבלה',
      },
      filter: {
        all: 'הכל',
        title: 'אפשרויות סינון',
        reset: 'איפוס סינון',
      },
      viewColumns: {
        title: 'Show Columns',
        titleAria: 'Show/Hide Table Columns',
      },
      selectedRows: {
        text: 'שורות נבחרו',
        delete: 'מחק',
        deleteAria: 'מחק את השורות הנבחרות',
      },
    },
    fixedHeaderOptions: {
      xAxis: false,
      yAxis: true
    },

    // customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
    //   <Toolbar
    //     displayData={displayData}
    //     selectedRows={selectedRows}
    //     setSelectedRows={setSelectedRows}
    //     data={data}
    //   />
    // )

  };
  if (customBarBull) {
    Object.assign(options, customBar);
  }

  const claclTotal = (tableState) => {
    var sum = {}
    setLength(tableState.displayData.length)
    Object.entries(totalRow).map(([key, value]) => {
      if (value === 'כמות מחליפים') {
        sum[value] = 0
        tableState.displayData.map((item) => {
          if (item.data[parseFloat(key)] === 'מוצר מחליף') {
            sum[value]++
          }
        })
      } else {
        var tarea_regex = /₪|%|,/gi;
        var prec_regex = /%/g;
        var nis_regex = /₪/g;
        if (tableState.displayData.length > 0) {
          // console.log(tableState.displayData)
          var isPrec = prec_regex.test(tableState.displayData[0]['data'][key])
          var isNis = nis_regex.test(tableState.displayData[0]['data'][key])
        }
        sum[value] = tableState.displayData.map(a => { return tarea_regex.test(a.data[parseFloat(key)]) ? parseFloat(a.data[parseFloat(key)].replace(tarea_regex, '')) : a.data[parseFloat(key)] })
          .reduce((total, tosum) => (total += isNaN(tosum) || !tosum ? 0 : isFinite(tosum) ? parseFloat(tosum) : 0), 0);

        if (isPrec) {
          // console.log('prec',sum[value])
          sum[value] = parseFloat(sum[value] / tableState.displayData.length).toFixed(2) + '%'
        } else if (isNis) {
          sum[value] = FormatILS(parseFloat(sum[value]))
        }

      }

    })

    // console.log(summ)
    JSON.stringify(summ) !== JSON.stringify(sum) && setSumm(sum)
  }


  return (
    <>
      {addTotalRow &&
        <SumCard
          length={length}
          sum={summ}
        />
      }
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          columns={columns}
          data={data}
          dir="rtl"
          options={options}
          title={title}
        />
      </MuiThemeProvider>
    </>
  );

}

export default SmartTable;


