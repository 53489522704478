import { Button } from '@material-ui/core';
import React,{useState,useEffect} from 'react'

export default function SupplierMap() {
  const [supplierMap, setSupplierMap] = useState([]);
  useEffect(()=>{
    var Suppliers = JSON.parse(localStorage.getItem("SuppliersNew"))
    if(Suppliers&&Suppliers.value&&(!supplierMap||supplierMap.length==0)){
      const supllierArr=Object.entries(Suppliers.value)
      setSupplierMap(supllierArr)
    }
  },[])
  const clear_supplier=()=>{
    localStorage.removeItem("SuppliersNew");
    window.location.reload();
  }
  return (<>
      <input type="text" list="data" style={{width:'10%',backgroundColor:'aliceblue',border: '1px',height:'22px'}}/>
      <datalist id="data">
      {supplierMap&&supplierMap.map(x=><option key={x[0]} value={`${x[1][0]} - ${x[0]}`}/> )}
      </datalist>
      <label style={{paddingRight:5,fontWeight:'bold'}}>  :suppliers </label>
      <Button style={{color:'white',fontWeight:'bold',paddingRight:'2%'}} onClick={()=>{clear_supplier()}}>רענן ספקים</Button>
 </> 
  )
}
