import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    marginBottom: '-5px',
    // right:'15px',
    // bottom:'15px'
    backgroundColor:'#bbdefb'
  },
  tableRow:{
    backgroundColor:'#bbdefb'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});


const SumCard = props => {
  var { length,sum } = props;
  // const [sum] = useState(props.sum);
  const classes = useStyles();
  // const [total, setTotal] = useState([]);
  // React.useEffect(() => {
  //   console.log(sum)
  // }, [sum])
  // console.log('sum'+ {...props.sum})
  // console.log('sum'+ props.sum)
  // console.log('total'+ total)
  // }, []);
  // console.log(sum)
  // console.log(total)
  return (
    <Card className={classes.card}>
      <Table>
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell align={'left'}>כמות: {length}</TableCell>
            {Object.keys(sum).map((key)=> (
              <TableCell
                align={'right'}
                key={key}
              >
                {`${key}: ${sum[key]}`}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>
    </Card>
  );
}
export default SumCard