import * as actionTypes from 'src/actions/suppliersActions';

const initialState = {
  suppliers_arr: [],
  suppliersPlain: [],
  colors_arr:[],
};

const suppliersReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case actionTypes.SET_SUPPLIERS: {
      return {
        ...state,
        suppliers_arr: action.payload.suppliers_arr,
        suppliersPlain: action.payload.suppliersPlain,
      } 
    }

    case actionTypes.SET_COLORS: {
      return {
        ...state,
        colors_arr: action.payload.colors_arr
      } 
    }

    default: {
      return state;
    }
  }
};

export default suppliersReducer;
