import axios from 'axios';
import moment from 'moment';
import {get_base_api_url} from '../conf'; 
export const SET_SUPPLIERS = 'SET_SUPPLIERS';
export const SET_COLORS = 'SET_COLORS';

export const setSuppliers = (data) => (dispatch) => dispatch({
  type: SET_SUPPLIERS,
  payload: { suppliers_arr:data.suppliers, suppliersPlain: data.suppliersPlain }
});

export const setColors = (colors_arr) => (dispatch) => {
  dispatch({
  type: SET_COLORS,
  payload: { colors_arr }
})};

export const getSuppliers = (access_token) => (dispatch) => {
  callSuppliersApi(access_token, callback => {
      dispatch(setSuppliers(callback));
  });
};
export const getColors = (access_token) => (dispatch) => {
  callColorsApi(access_token, callback => {
      dispatch(setColors(callback.colors));
  });
};
  
const callSuppliersApi = async (access_token, callback) =>  {
  var Suppliers = JSON.parse(localStorage.getItem("SuppliersNew"))
  if( !Suppliers || moment(Suppliers.timestamp) < moment(new Date()).add(-30, 'days') ){
    await axios.get(get_base_api_url()+'/api/suppliers')
    .then(res => {
      var allSuppliers = Object.assign({}, ...(res.data.results).map(({ID, post_title}) => ({ [ID] : [post_title] })))
      let plainValue = res.data.results.sort( ( a, b ) => b.post_title - a.post_title );
      var object = {value: allSuppliers, PlainValue: plainValue, timestamp: moment(new Date())}
      localStorage.setItem("SuppliersNew", JSON.stringify(object));

      return callback({
        suppliers: allSuppliers,
        suppliersPlain: plainValue
      });
    })
  }else{
    return callback({
      suppliers: Suppliers.value,
      suppliersPlain: Suppliers.PlainValue
    });
  }
};

const callColorsApi = async (access_token, callback) =>  {
  var localAllColors = JSON.parse(localStorage.getItem("allColors"))
  if( !localAllColors || moment(localAllColors.timestamp) < moment(new Date()).add(-30, 'days') ){

    await axios.get(get_base_api_url()+'/api/get_colors')
    .then(res => {
      var allColors = Object.assign({}, ...(res.data.results).map(({slug, name}) => ({[slug] : [name] })))

      var object = {value: allColors, timestamp: moment(new Date())}
      localStorage.setItem("allColors", JSON.stringify(object));

      return callback({
        colors: allColors,
      });

    })
  }else{
    return callback({
      colors: localAllColors.value,
    });
  }
};