import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import { withStyles } from '@material-ui/core/styles';
const defaultToolbarStyles = {
  iconButton: {}
};

const CustomToolbar = props => {
  const {addRow, action  } = props;
  const handleClick = () => {
    addRow()
  };

  return (
    <React.Fragment>
      <Tooltip title={action === 'add_row' ? 'הוסף שורה' : action === 'refresh' ? 'רענן נתונים': ''}>
        <IconButton
          onClick={handleClick}
        >
          {action === 'add_row' ? <AddIcon/> : action === 'refresh' ? <RefreshIcon/>: ''}
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
  
}

export default withStyles(defaultToolbarStyles, { name: 'CustomToolbar' })(
  CustomToolbar
);