import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const ImgDialog = (props) => {
  const { dialogTitle,imgSrc, ...rest } = props;
  const [open, setOpen] = React.useState(false);
  const [Img, setImg] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  let new_imgSrc=''
if(imgSrc){
  let new_imgSrc_arr=imgSrc.split('.');
  new_imgSrc_arr.forEach((x,i)=>{
    if((i+1)<new_imgSrc_arr.length){
      if(i!=0){
        new_imgSrc+="."
      }
      new_imgSrc+=x;
    }
  })
  new_imgSrc+='-150x150.'+new_imgSrc_arr[new_imgSrc_arr.length-1];
}


  return (
    // <React.Fragment>
    <div>
      <img
       onError={()=>{setImg(imgSrc)}}
        height={'auto'}
        onClick={handleClickOpen}
        src={Img?Img:new_imgSrc}
        width={'40px'} 
        loading='lazy'
        alt=''
      />
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <img
            src={imgSrc}
            width={'100%'}
            loading='lazy'
            alt=''
          />
        </DialogContent>
      </Dialog>
    </div>
    // </React.Fragment>
  );
}
export default ImgDialog