import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import uuid from 'uuid/v1';
import moment from 'moment';
import {get_base_api_url} from './../../conf';
import { makeStyles } from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';
import {
  Card,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Tooltip
} from '@material-ui/core';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import SendIcon from '@material-ui/icons/Send';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import PaymentIcon from '@material-ui/icons/PaymentOutlined';
import MailIcon from '@material-ui/icons/MailOutlineOutlined';

const useStyles = makeStyles((theme) => ({
  root: {},
  value: {
    fontWeight: theme.typography.fontWeightMedium
  },
  type: {
    fontWeight: theme.typography.fontWeightMedium
  }
}));


const icons = {
  invite: <SendIcon />,
  message: <MailIcon />,
  payout: <PaymentIcon />
};

function Notifications({ className, ...rest }) {
  const classes = useStyles();

  const session = useSelector((state) => state.session);
  const [topTen, setTopTen] = useState([]);

  useEffect(() => {
    let mounted = true;

    const fetchTopTen = (days) => {
      axios.get(get_base_api_url() + `/api/dashboard/best_product?date=${ moment(Date.now() - days * 24 * 3600 * 1000).format('YYYY-MM-DD')}&limit=10` , { headers: { 'authorization': session.access_token, }})
      .then((response) => {
        console.log(response)
        if (mounted) {
          console.log(response.data.results[0])
          setTopTen(response.data.results[0]);
        }
      });
    };

    fetchTopTen(7);

    return () => {
      mounted = false;
    };

  } , [])

  if (topTen.length < 1) {
    return(
      <div>
        <Skeleton
          height={110}
          style={{marginTop: '15px'}}
          variant="rect"
          width={'100%'}
        />
      </div>
    );
  }

  return (
    <Card 
      {...rest}
      className={clsx(classes.root, className)}
    >
      <List>
        {topTen.map((product, i) => (
          <ListItem
            divider={i < topTen.length - 1}
            key={product.order_item_name}
          >
            <ListItemIcon>{AddShoppingCartIcon}</ListItemIcon>
            <ListItemText>
              <Typography variant="body1">
                <span className={classes.value}>{product.order_item_name}</span>
              </Typography>
            </ListItemText>
            {/* <ListItemSecondaryAction>
              <Tooltip title="View">
                <IconButton
                  edge="end"
                  size="small"
                >
                  <ArrowForwardIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction> */}
          </ListItem>
        ))}
      </List>
    </Card>
  );
}

Notifications.propTypes = {
  className: PropTypes.string
};

export default Notifications;
