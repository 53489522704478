
export const get_base_api_url = () => {
    const isLocalhost = Boolean(
        window.location.hostname === 'localhost' ||
          // [::1] is the IPv6 localhost address.
          window.location.hostname === '[::1]' ||
          // 127.0.0.1/8 is considered localhost for IPv4.
          window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
          )
      );
      if(isLocalhost) {
        return 'http://localhost:5555/v1'
      }else{
        // return'https://owhxxeblu0.execute-api.eu-west-1.amazonaws.com/prod/v1' 
        return 'https://backend.shoesonline.co.il:5555/v1'
      }
}

export const get_base_api_url_python = () => {
  const isLocalhost = Boolean(
      window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    );
    if(isLocalhost) {
      return 'http://localhost:3001/ShoesOnline/v1/'
    }else{
      return 'https://py-api-shoesonline-mhsd6l5c6a-ew.a.run.app/ShoesOnline/v1/'
    }
}


