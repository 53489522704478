import axios from 'axios';
import {get_base_api_url} from '../conf';

require('dotenv').config()
export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';

export const setLoginUser = (user, access_token) => (dispatch) => dispatch({
  type: SESSION_LOGIN,
  payload: { user , access_token}
});

export const setLocalUser = (user) => (dispatch) => {
  dispatch({
    type: SESSION_LOGIN,
    payload: {
      ...user
    }
  })
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userLogd");
  localStorage.removeItem("SuppliersNew");
  localStorage.removeItem("allColors");
  dispatch({
  type: SESSION_LOGOUT
  })
};



export const login = (user, history) => (dispatch) => {
  callLoginApi(user, callback => {
      dispatch(setLoginUser(callback.user,callback.access_token));
      dispatch({ 
        type: SESSION_LOGIN,
        payload: {
          user: callback.user,
          access_token:callback.access_token,
          loginError:callback.error
        }
      })
      history.push('/dashboard');
  });
};

const callLoginApi = (user, callback) =>  {
   axios
    .post(
      get_base_api_url()+`/api/auth/login`,
      user,
      { headers: { 'Content-Type': 'application/json' } }
    )
    .then( async (result) => {
      if (result) {
        if (!result.data.user) {
          return callback({
            user: {},
            error:!result.data.mailExist ? 'מייל לא קיים': 'קרתה שגיאה, נסה שוב' 
          });
        } else {
          localStorage.setItem('userLogd',JSON.stringify({user:result.data.user, access_token:result.data.access_token}))
          return callback({
            user: result.data.user,
            access_token:result.data.access_token,
            error: null
           });
        }
      }
    });
};

export const checkToken = (token) => (dispatch) => {
  callTokenApi(token ,callback=> {
    if(!callback.isValid){
      localStorage.removeItem("userLogd");
      dispatch({
      type: SESSION_LOGOUT
      })
    }
  });
};

const callTokenApi = (token, callback) =>  {
  
  axios
   .post(
     get_base_api_url()+`/api/auth/check_token`,
     {token:token}
   )
   .then( async (result) => {
     if (result) {
        return callback({
        isValid: result.data.isValid
        });
     }
   });
};