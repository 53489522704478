/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import DashboardAnalyticsView from './views/DashboardAnalytics';
import DashboardDefaultView from './views/DashboardDefault';
import OverviewView from './views/Overview';
import PresentationView from './views/Presentation';

export default [
  {
    path: '/index.html',
    exact: true,
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('src/views/Login'))
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('src/views/Register'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('src/views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('src/views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/calendar',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/Calendar'))
      },
      {
        path: '/changelog',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/Changelog'))
      },
      {
        path: '/chat',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/Chat'))
      },
      {
        path: '/chat/:id',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/Chat'))
      },
      {
        path: '/leads',
        exact: true,
        roll: ['admin', 'developer', 'customer_service'  ,'customer_service_manager'],
        component: lazy(() => import('src/views/GfLeads'))
      },
      {
        path: '/ServiceActions',
        exact: true,
        roll: ['admin', 'developer','customer_service_manager','hr','ppc'],
        component: lazy(() => import('src/views/ServiceActions'))
      },
      // {
      //   path: '/leads/:email',
      //   exact: true,
      //   roll: ['developer'],
      //   component: lazy(() => import('src/views/Leads'))
      // },
      {
        path: '/components/buttons',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/Buttons'))
      },
      {
        path: '/components/cards',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/Cards'))
      },
      {
        path: '/components/chips',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/Chips'))
      },
      {
        path: '/components/forms',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/Forms'))
      },
      {
        path: '/components/lists',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/Lists'))
      },
      {
        path: '/components/modals',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/Modals'))
      },
      {
        path: '/components/typography',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/Typography'))
      },
      {
        path: '/dashboards/analytics',
        exact: true,
        roll: ['developer'],
        component: DashboardAnalyticsView
      },
      {
        path: '/dashboards/default',
        exact: true,
        roll: ['developer'],
        component: DashboardDefaultView
      },
      {
        path: '/invoices/:id',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/InvoiceDetails'))
      },
      {
        path: '/kanban-board',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/KanbanBoard'))
      },
      {
        path: '/mail',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/Mail'))
      },
      {
        path: '/management/customers',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/CustomerManagementList'))
      },
      {
        path: '/management/customers/:id',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/CustomerManagementDetails'))
      },
      {
        path: '/management/customers/:id/:tab',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/CustomerManagementDetails'))
      },
      {
        path: '/management/projects',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/ProjectManagementList'))
      },
      {
        path: '/management/orders',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/OrderManagementList'))
      },
      {
        path: '/management/orders/:id',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/OrderManagementDetails'))
      },
      {
        path: '/dashboard',
        exact: true,
        roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager' ,'developer', 'warehouse', 'finance', 'trade', 'instagram','ppc'],
        component: DashboardAnalyticsView
      },
      {
        path: '/presentation',
        exact: true,
        roll: ['developer'],
        component: PresentationView
      },
      {
        path: '/profile/:id',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/Profile'))
      },
      {
        path: '/profile/:id/:tab',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/Profile'))
      },
      {
        path: '/projects/create',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/ProjectCreate'))
      },
      {
        path: '/projects/:id',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/ProjectDetails'))
      },
      {
        path: '/projects/:id/:tab',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/ProjectDetails'))
      },
      {
        path: '/projects',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/ProjectList'))
      },
      {
        path: '/settings',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/Settings'))
      },
      {
        path: '/settings/:tab',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/Settings'))
      },
      {
        path: '/social-feed',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/SocialFeed'))
      },
      {
        path: '/getting-started',
        exact: true,
        roll: ['developer'],
        component: lazy(() => import('src/views/GettingStarted'))
      },
      {
        path: '/profitability',
        exact: true,
        roll: ['developer', 'admin', 'finance', 'trade','ppc'],
        component: lazy(() => import('src/views/ProfitabilityList'))
      },
      {
        path: '/profitability/CancellationsReport',
        exact: true,
        roll: ['developer', 'admin', 'customer_service'  ,'customer_service_manager', 'trade','ppc'],
        component: lazy(() => import('src/views/CancellationsReport'))
      },
      {
        path: '/profitability/OutOfStockReport',
        exact: true,
        roll: ['developer', 'admin', 'customer_service'  ,'customer_service_manager', 'trade','ppc'],
        component: lazy(() => import('src/views/OutOfStockReport'))
      },
      {
        path: '/profitability/suppliers_report',
        exact: true,
        roll: ['developer', 'admin', 'finance', 'trade','ppc'],
        component: lazy(() => import('src/views/ProfitabilityReport'))
      },
      {
        path: '/profitability/supply_sales_report',
        exact: true,
        roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager' ,'developer', 'trade' ,'customer_service_manager','ppc','finance'],
        component: lazy(() => import('src/views/SupplySalesReport'))
      },
      {
        path: '/profitability/canceled_products',
        exact: true,
        roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager' ,'developer', 'trade','ppc'],
        component: lazy(() => import('src/views/CanceledProducts'))
      },
      {
        path: '/profitability/customer_club',
        exact: true,
        roll: ['admin', 'content','finance' ,'developer','customer_service','customer_service_manager', 'trade','ppc'],
        component: lazy(() => import('src/views/CustomerDetails'))
      },
      {
        path: '/profitability/cost_Gaps',
        exact: true,
        roll: ['admin', 'content','finance' ,'developer'],
        component: lazy(() => import('src/views/CostGaps'))
      },
      {
        path: '/profitability/VouchersReport',
        exact: true,
        roll: ['admin','developer'],
        component: lazy(() => import('src/views/VouchersReport'))
      },
      {
        path: '/profitability/CouponReport',
        exact: true,
        roll: ['developer', 'admin', 'finance','ppc','content', 'customer_service','hr'],
        component: lazy(() => import('src/views/CouponReport'))
      },
      {
        path: '/profitability/supplierRefund',
        exact: true,
        roll: ['developer', 'admin', 'finance','warehouse' ],
        component: lazy(() => import('src/views/SupplierRetrunReport/SupplierRetrunReport'))
      },
      // {
      //   path: '/profitability/canceled_products',
      //   exact: true,
      //   roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager' ,'developer'],
      //   component: lazy(() => import('src/views/CustomerDetails'))
      // },
      {
        path: '/delivery_report/:order_status',
        exact: true,
        roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager' ,'developer'],
        component: lazy(() => import('src/views/DeliveryReport'))
      },
      {
        path: '/suspect_customer',
        exact: true,
        roll: ['admin', 'customer_service'  ,'customer_service_manager' ,'developer'],
        component: lazy(() => import('src/views/SuspectCustomer'))
      },
      {
        path: '/pick_up',
        exact: true,
        roll: ['developer', 'admin'],
        component: lazy(() => import('src/views/PickUp'))
      },
      {
        path: '/delivery_summary',
        exact: true,
        roll: ['admin', 'customer_service'  ,'customer_service_manager' ,'developer'],
        component: lazy(() => import('src/views/DeliveryStatus'))
      },
      {
        path: '/filter-statuses',
        exact: true,
        roll: ['admin', 'customer_service'  ,'customer_service_manager' ,'developer', 'trade'],
        component: lazy(() => import('src/views/FilterList'))
      },
      {
        path: '/international_shipping',
        exact: true,
        roll: ['developer', 'admin'],
        component: lazy(() => import('src/views/InternationalShipping'))
      },
      {
        path: '/stolen_card',
        exact: true,
        roll: ['admin', 'customer_service'  ,'customer_service_manager' ,'developer'],
        component: lazy(() => import('src/views/StolenCard'))
      },
      {
        path: '/thieves',
        exact: true,
        roll: ['admin', 'customer_service'  ,'customer_service_manager' ,'developer'],
        component: lazy(() => import('src/views/Thieves'))
      },
      {
        path: '/denies',
        exact: true,
        roll: ['admin', 'customer_service'  ,'customer_service_manager' ,'developer'],
        component: lazy(() => import('src/views/Denies'))
      },
      {
        path: '/search_product',
        exact: true,
        roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager' ,'developer','warehouse','ppc'],
        component: lazy(() => import('src/views/SearchProduct'))
      },
      {
        path: '/all_products',
        exact: true,
        roll: ['admin', 'content', 'trade', 'developer','warehouse','ppc'],
        component: lazy(() => import('src/views/AllProduct'))
      },
      {
        path: '/ActionOnProduct',
        exact: true,
        roll: ['admin', 'content', 'trade', 'developer','warehouse','ppc'],
        component: lazy(() => import('src/views/ActionOnProduct'))
      },
      {
        path: '/MsFeed',
        exact: true,
        roll: ['admin', 'content', 'trade', 'developer','warehouse','ppc'],
        component: lazy(() => import('src/views/MsFeed'))
      },
      {
        path: '/ActionOnFile',
        exact: true,
        roll: ['admin', 'content', 'developer','warehouse'],
        component: lazy(() => import('src/views/ActionOnFile'))
      },
      {
        path: '/ViewFile',
        exact: true,
        roll: ['admin', 'content', 'developer','warehouse'],
        component: lazy(() => import('src/views/ViewFile/ViewFile'))
      },
      {
        path: '/users_history',
        exact: true,
        roll: ['developer', 'admin'],
        component: lazy(() => import('src/views/UsersHistory'))
      },
      {
        path: '/ipn_errors',
        exact: true,
        roll: ['admin', 'developer'],
        component: lazy(() => import('src/views/Ipn_errors'))
      },
      {
        path: '/wallet_log',
        exact: true,
        roll: ['admin', 'developer', 'customer_service'  ,'customer_service_manager' ],
        component: lazy(() => import('src/views/wallet_log'))
      },
      {
        path: '/warehouse_inventory',
        exact: true,
        roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager' ,'developer','warehouse', 'trade', 'instagram','ppc'],
        component: lazy(() => import('src/views/WarehouseInventory/WarehouseStock/index'))
      },
      {
        path: '/Storage',
        exact: true,
        roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager' ,'developer','warehouse', 'trade', 'instagram','ppc'],
        component: lazy(() => import('src/views/Storage/index'))
      },
      // {
      //   path: '/Storage2',
      //   exact: true,
      //   roll: ['admin',  'developer','warehouse'],
      //   component: lazy(() => import('src/views/Storage2/index'))
      // },
      
      // {
      //   path: '/warehouse_inventory/add_products',
      //   exact: true,
      //   roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager' ,'developer','warehouse'],
      //   component: lazy(() => import('src/views/WarehouseInventory'))
      // },
      {
        path: '/warehouse_inventory/item_size_data',
        exact: true,
        roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager' ,'developer','warehouse','ppc'],
        component: lazy(() => import('src/views/WarehouseInventory/item_size_data/Index'))
      },
      {
        path: '/warehouse_inventory/remove_item_stock',
        exact: true,
        roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager' ,'developer','warehouse','ppc'],
        component: lazy(() => import('src/views/WarehouseInventory/RemoveItemStock/index'))
      },
      {
        path: '/warehouse_inventory/remove_item_stock_balk',
        exact: true,
        roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager' ,'developer','warehouse','ppc'],
        component: lazy(() => import('src/views/WarehouseInventory/RemoveItemStockBalk'))
      },
      {
        path: '/warehouse_inventory/manage_warehouse',
        exact: true,
        roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager' ,'developer','warehouse','ppc'],
        component: lazy(() => import('src/views/WarehouseInventory/ManageWarehouse'))
      },
      {
        path: '/admin_tools/sign_up',
        exact: true,
        roll: ['developer', 'admin','customer_service_manager'],
        component: lazy(() => import('src/views/AdminTools/signUp'))
      },
      {
        path: '/admin_tools/manually_events',
        exact: true,
        roll: ['developer', 'admin', 'content', 'customer_service'  ,'customer_service_manager','ppc'],
        component: lazy(() => import('src/views/AdminTools/manuallyEvents'))
      },
      {
        path: '/edit_order_statuses',
        exact: true,
        roll: ['admin','developer', 'content'],
        component: lazy(() => import('src/views/EditOrderStatuses'))
      },
      {
        path: '/suppliers_status_history',
        exact: true,
        roll: ['admin','developer'],
        component: lazy(() => import('src/views/SuppliersStatusHistory'))
      },
      {
        path: '/supplier_store',
        exact: true,
        roll: ['admin' ,'developer'],
        component: lazy(() => import('src/views/SupplierStore/'))
      },
      {
        path: '/handle_searches',
        exact: true,
        roll: ['admin','developer' , 'content','ppc'],
        component: lazy(() => import('src/views/SiteSearches'))
      },
      {
        path: '/two_suppliers_logs_item',
        exact: true,
        roll: ['admin','developer','content','ppc'],
        component: lazy(() => import('src/views/TwoSuppliersLogs/ItemLog'))
      },
      {
        path: '/two_suppliers_logs_new_sizes',
        exact: true,
        roll: ['admin','developer' ,'content','ppc'],
        component: lazy(() => import('src/views/TwoSuppliersLogs/NewSizes'))
      },
      {
        path: '/two_suppliers_logs_variations',
        exact: true,
        roll: ['admin','developer' ,'content','ppc'],
        component: lazy(() => import('src/views/TwoSuppliersLogs/VariationsLog'))
      },
      {
        path: '/events/list',
        exact: true,
        roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager' ,'developer'],
        component: lazy(() => import('src/views/Events/EventsList'))
      },
      {
        path: '/events/history',
        exact: true,
        roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager' ,'developer'],
        component: lazy(() => import('src/views/Events/HistoryList'))
      },
      {
        path: '/events/waiting_list',
        exact: true,
        roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager' ,'developer'],
        component: lazy(() => import('src/views/Events/WaitingList'))
      },
      {
        path: '/events/edit_waiting_list',
        exact: true,
        roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager' ,'developer'],
        component: lazy(() => import('src/views/Events/EditWaitingList'))
      },
      {
        path: '/events/delay_cancel_events',
        exact: true,
        roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager' ,'developer'],
        component: lazy(() => import('src/views/Events/DelayCancel'))
      },
      {
        path: '/events/templates',
        exact: true,
        roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager' ,'developer'],
        component: lazy(() => import('src/views/Events/Templates'))
      },
      {
        path: '/events/send_free_events',
        exact: true,
        roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager' ,'developer'],
        component: lazy(() => import('src/views/Events/SendFreeEvents'))
      },
      {
        path: '/service_representatives/total',
        exact: true,
        roll: ['admin', 'customer_service'  ,'customer_service_manager' ,'developer'],
        component: lazy(() => import('src/views/ServiceRepresentatives/Total'))
      },
      {
        path: '/service_representatives/manager/:tab',
        exact: true,
        roll: ['admin','developer', 'customer_service'  ,'customer_service_manager'],
        component: lazy(() => import('src/views/ServiceRepresentatives/Manager'))
      },
      {
        path: '/service_representatives/processing/:tab',
        exact: true,
        roll: ['admin', 'customer_service'  ,'customer_service_manager' ,'developer'],
        component: lazy(() => import('src/views/ServiceRepresentatives/Processing'))
      },
      {
        path: '/stock_meneger',
        exact: true,
        roll: ['admin', 'customer_service'  ,'customer_service_manager' ,'developer','warehouse', 'trade','ppc'],
        component: lazy(() => import('src/views/StockMeneger'))
      },
      {
        path: '/return_item_stock/:tab',
        exact: true,
        roll: ['admin', 'customer_service'  ,'customer_service_manager' ,'developer','warehouse'],
        component: lazy(() => import('src/views/ReturnItemStock'))
      },
      {
        path: '/SuppliersReturn',
        exact: true,
        roll: ['admin', 'customer_service'  ,'customer_service_manager','finance' ,'developer','warehouse','hr'],
        component: lazy(() => import('src/views/SuppliersReturn'))
      },
      {
        path: '/SuppliersReturn/TempReturnPage',
        exact: true,
        roll: ['admin', 'customer_service'  ,'customer_service_manager','finance' ,'developer','warehouse','hr'],
        component: lazy(() => import('src/views/SuppliersReturn/TempReturnPage.jsx'))
      },
      {
        path: '/return_valid_items/',
        exact: true,
        roll: ['admin', 'developer', 'warehouse'],
        component: lazy(() => import('src/views/ReturnValidItems'))
      },
      {
        path: '/supply_items_open',
        exact: true,
        roll: ['admin','developer'],
        component: lazy(() => import('src/views/SupplyItemsOpen'))
      },
      {
        path: '/payments',
        exact: true,
        roll: ['admin','developer'],
        component: lazy(() => import('src/views/Payments/TransactionHistory'))
      },
      {
        path: '/payments/payouts',
        exact: true,
        roll: ['admin','developer'],
        component: lazy(() => import('src/views/Payments/Payouts'))
      },
      {
        path: '/payments/sum_deals',
        exact: true,
        roll: ['admin','developer'],
        component: lazy(() => import('src/views/Payments/SumDeals'))
      },
      {
        path: '/warehouse_inventory/warehouse_logs/:by',
        exact: true,
        roll: ['admin', 'customer_service'  ,'customer_service_manager' ,'developer'],
        component: lazy(() => import('src/views/WarehouseInventory/EventsLog'))
      },
      {
        path: '/update_users_info/:source',
        exact: true,
        roll: ['admin', 'customer_service'  ,'customer_service_manager' ,'developer'],
        component: lazy(() => import('src/views/UpdateUsersInfo'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];
