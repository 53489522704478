import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Skeleton from "@material-ui/lab/Skeleton";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import axios from 'src/utils/axios';
import GenericMoreButton  from 'src/components/GenericMoreButton';
import Chart from './Chart';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  chartContainer: {
    padding: theme.spacing(3)
  },
  chart: {
    height: 281
  },
  statsContainer: {
    display: 'flex'
  },
  statsItem: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3, 2),
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  }
}));

function EarningsSegmentation({ className, data, loading,  ...rest }) {
  const classes = useStyles();
  const [earnings, setEarnings] = useState([]);
  const percentage = (partialValue, totalValue) => {
    return (100 * partialValue) / totalValue;
  } 

  var sum = data.reduce(function(prev, cur) {
    return prev + cur.count;
  }, 0);

  var chart_data = data.map((item,i) => 
  Object.assign(item, {
      id:i, 
      label:item.status, 
      value:Math.round(percentage(item.count,sum)), 
      color: item.status == 'סגור' ? colors.indigo[100] :item.status == 'מעקב' ? colors.indigo[300]: item.status == 'פתוח' ? colors.indigo[500]: ''
    })
  )
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        // action={<GenericMoreButton />}
        title="סטטוסי פניות באחוזים"
      />
      <Divider />
      <CardContent className={classes.content}>
        <div className={classes.chartContainer}>
          {chart_data.length > 0 && !loading && <Chart
            className={classes.chart}
            data={chart_data}
          />} 
          {loading && <Skeleton
            className={classes.chart}
            animation="wave" 
            variant="circle" 
            width={281} 
            height={281}
            style={{margin: '0 auto'}}
          />}
        </div>
        <Divider />
        <div className={classes.statsContainer}>
          {chart_data.length > 0 && !loading && chart_data.map((item) => 
            <div
              className={classes.statsItem}
              key={item.id}
            >
              <Typography
                align="center"
                component="h6"
                gutterBottom
                variant="overline"
              >
                {item.label}
              </Typography>
              <Typography
                align="center"
                variant="h4"
              >
                {item.value}%
              </Typography>
            </div>
          )}
          {loading &&
            <div
              className={classes.statsItem}
            > 
              <Skeleton
                className={classes.statsItem}
                variant="rect"
                animation="wave"
              />
          </div>}
        </div>
      </CardContent>
    </Card>
  );
}

EarningsSegmentation.propTypes = {
  className: PropTypes.string
};

export default EarningsSegmentation;
