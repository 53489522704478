import React, { Suspense, useState, useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 256
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56
    }
  }
}));

function Dashboard({ route }) {
  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const session = useSelector((state) => state.session);

  useEffect(() => {
    moment.updateLocale('en', {
      relativeTime : {
        future: "עוד %s",
        past:   "לפני %s",
        s  : 'כמה שניות',
        ss : '%d שניות',
        m:  "דקה",
        mm: "%d דקות",
        h:  "שעה",
        hh: "%d שעות",
        d:  "יום",
        dd: "%d ימים",
        w:  "שבוע",
        ww: "%d שבועות",
        M:  "חודש",
        MM: "%d חודשים",
        y:  "a שנה",
        yy: "%d שנים"
      }
  });
  }, []); 

  return (
    <>
      <TopBar session={session} onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
      <NavBar
        onMobileClose={setOpenNavBarMobile}
        openMobile={openNavBarMobile}
        session={session}
      />
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </div>
        
      </div>
    </>
  );
}

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
