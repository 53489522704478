import React from 'react';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { CartContext } from './components/CartContext'
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { themeWithRtl } from './theme';
import { configureStore } from './store';
import routes from './routes';
import GoogleAnalytics from './components/GoogleAnalytics';
import CookiesNotification from './components/CookiesNotification';
import { useSelector } from 'react-redux';
import { SnackbarProvider, useSnackbar } from 'notistack';
import ScrollReset from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';
// import DirectionToggle from './components/DirectionToggle';
import AuthGuard from './components/AuthGuard';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/main.scss';

// require('dotenv').config();
// console.log(process.env )
const history = createBrowserHistory();
const store = configureStore();

function App() {

  return (
    <StoreProvider store={store}>
      <CartContext>
        <ThemeProvider theme={themeWithRtl}>
          <StylesProvider direction="rtl">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}>
                <Router history={history}>
                  <ScrollReset />
                  <GoogleAnalytics />
                  {/* <CookiesNotification /> */}
                  <AuthGuard routes={routes} history={history}/>
                </Router>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </ThemeProvider>
      </CartContext>
    </StoreProvider>
  );
}

export default App;
