import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card, CardHeader, CardContent, Divider
} from '@material-ui/core';
import GenericMoreButton from 'src/components/GenericMoreButton';
import Chart from './Chart';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {},
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  inner: {
    height: 375,
    minWidth: 500
  },
  chart: {
    height: '100%'
  }
}));

function PerformanceOverTime({ className, data, loading, ...rest }) {
  const classes = useStyles();
  const chart_data = {
      data: data.map(item => item.count),
      labels: data.map(item => moment(item.date).format('DD-MM-YYYY'))
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        // action={<GenericMoreButton />}
        title="סגירת פניות"
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            {data.length >0 && <Chart
              className={classes.chart}
              data={chart_data.data}
              labels={chart_data.labels}
            />}
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
}

PerformanceOverTime.propTypes = {
  className: PropTypes.string
};

export default PerformanceOverTime;
