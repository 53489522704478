import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {get_base_api_url} from './../../conf';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  Typography,
  Grid,
  colors
} from '@material-ui/core';
import axios from 'axios';
import Label from 'src/components/Label';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  overline: {
    marginTop: theme.spacing(1)
  }
}));

function Statistics({ className, ...rest }) {
  const classes = useStyles();
  const session = useSelector((state) => state.session);


  const [in_out_money, setIn_out_money] = useState(null);

  // const [bestProd_arr,setBestProd_arr] = useState([])
  // const [in_out,setIn_out] = useState({})

  // const get_best_products= (days) =>{
  //   axios.get(`https://api.shoesonline.co.il/dashboard/best_product?date=${ moment(Date.now() - days * 24 * 3600 * 1000).format('YYYY-MM-DD')}&limit=10`)
  //     .then(res => {
  //       setBestProd_arr( res.data.results );
  //       console.log(res.data.results)
  //     })
  //   console.log(bestProd_arr)
  // }
  // const get_in_out_money= (days)=>{
  //   axios.get(`https://api.shoesonline.co.il/dashboard/in_out_money?date=${ moment(Date.now() - days * 24 * 3600 * 1000).format('YYYY-MM-DD')}`)
  //     .then(res => {
  //       setIn_out_arr(res.data.results[0]);
  //       console.log(res.data.results)
  //     })
  //   console.log(bestProd_arr)
  // }

  
  // useEffect(() => {
  //   get_best_products(7)
  //   get_in_out_money(7)
  // }, []);

  const FormatILS = (value) =>{
    const nf = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'ILS',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    return nf.format(value);
  }


  useEffect(() => {
    let mounted = true;

    const fetchIn_out_money = (days) => {
      axios.get(get_base_api_url() + `/api/dashboard/in_out_money?date=${ moment(Date.now() - days * 24 * 3600 * 1000).format('YYYY-MM-DD')}` , { headers: { 'authorization': session.access_token, }})
      .then((response) => {
        if (mounted) {
          setIn_out_money(response.data.results[0]);
        }
      });
    };

    // const fetchIn_out_money = () => {
    //   axios.get('/api/account/statistics').then((response) => {
    //     if (mounted) {
    //       setStatistics(response.data.statistics);
    //     }
    //   });
    // };

    fetchIn_out_money(7);

    return () => {
      mounted = false;
    };
  }, []);

  if (!in_out_money) {
    return(
      <div>
        <Skeleton
          height={110}
          style={{marginTop: '15px'}}
          variant="rect"
          width={'100%'}
        />
      </div>
    );
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="center"
        container
        justify="space-between"
      >
        <Grid
          className={classes.item}
          item
          md={3}
          sm={6}
          xs={12}
        >
          <Typography variant="h2">
            {FormatILS(in_out_money.sum_cancel)}
          </Typography>
          <Typography
            className={classes.overline}
            variant="overline"
          >
            החזרות בשבוע האחרון
          </Typography>
        </Grid>
        <Grid
          className={classes.item}
          item
          md={3}
          sm={6}
          xs={12}
        >
          <Typography variant="h2">
            {FormatILS(in_out_money.sum_new_orders)}
          </Typography>
          <Typography
            className={classes.overline}
            variant="overline"
          >
            קניות בשבוע האחרון
          </Typography>
        </Grid>
        <Grid
          className={classes.item}
          item
          md={3}
          sm={6}
          xs={12}
        >
          <Typography variant="h2">{in_out_money.count_new_orders}</Typography>
          <Typography
            className={classes.overline}
            variant="overline"
          >
            הזמנות חדשות
          </Typography>
        </Grid>
        <Grid
          className={classes.item}
          item
          md={3}
          sm={6}
          xs={12}
        >
          <div className={classes.titleWrapper}>
            <Typography
              component="span"
              variant="h2"
            >
              {in_out_money.count_cancel}
            </Typography>
            {/* <Label
              className={classes.label}
              color={colors.green[600]}
            >
              הזמנות שבוטלו
            </Label> */}
          </div>
          <Typography
            className={classes.overline}
            variant="overline"
          >
            הזמנות שבוטלו
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

Statistics.propTypes = {
  className: PropTypes.string
};

export default Statistics;
