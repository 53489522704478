import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from "react-redux";
import { Container, Grid } from '@material-ui/core';
import Page from 'src/components/Page';
import Header from './Header';
import Overview from './Overview';
import EventsHistoryListTable from './EventsHistoryListTable';

import FinancialStats from './FinancialStats';
import PerformanceOverTime from './PerformanceOverTime';
import EarningsSegmentation from './EarningsSegmentation';
import TopReferrals from './TopReferrals';
import MostProfitableProducts from './MostProfitableProducts';
import CustomerActivity from './CustomerActivity';
import LatestOrders from './LatestOrders';
import moment from 'moment';
import { get_base_api_url } from "../../conf";
import {getAllStatusTranslate, getSupply_statuses} from '../../Functions/Admin'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function DashboardAnalytics() {
  const classes = useStyles();
  const session = useSelector((state) => state.session);
  const StatusTranslate = getAllStatusTranslate()

  const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
  const [endDate, setEndDate] = useState(moment());
  const [loading, setLoading] = useState({
    get_sum:false,
    get_sum_status:false
  });
  const [sumResult, setSumResult] = useState([]);
  const [chartResult, setChartResult] = useState([]);
  const [eventsResult, setEventsResult] = useState([]);

  const [sumLoading, setSumLoading] = useState(false);
  const [chartLoading, setChartLoading] = useState(false);
  const [eventsLoading, setEventsLoading] = useState(false);

  const GetUserSum = (action) => {

    console.log('in' ,action,session.user.wp_user_id)

    action === 'get_sum_status' ? setChartLoading(true) : setSumLoading(true)
    axios.get(get_base_api_url()+`/api/service_representatives/user_sum?start=${moment(startDate).format('YYYY-MM-DD')}&end=${moment(endDate).format('YYYY-MM-DD')}&uid=${session.user.wp_user_id}&action=${action}`, 
      { headers: { 'authorization': session.access_token, } })
      .then(res => {
        if(action === 'get_sum_status'){
          res.data.results.length && setChartResult(res.data.results) 
          setChartLoading(false)
        }else{
          console.log(res.data.results)
          res.data.results.length && setSumResult(res.data.results);
          setSumLoading(false)
        }

      }).catch(error => {
        action === 'get_sum_status' ? setChartLoading( false): setSumLoading( false);
      });
  }

  const GetEventsHistory = () => {

    setEventsLoading(true)
    axios.get(get_base_api_url()+`/api/events/get_waiting_items`, 
      { headers: { 'authorization': session.access_token, } })
      .then(res => {
        setEventsResult(res.data.results) 
        setEventsLoading(false)
      }).catch(error => {
        setEventsLoading( false);
      });
  }

  useEffect(() => {

    if(session.user.wp_user_id){
      GetUserSum('get_sum');
      GetUserSum('get_sum_status');
      // if(eventsResult.length <= 0){
      //   GetEventsHistory()
      // }
    }

  }, [session, startDate, endDate]);

  return (
    <Page
      className={classes.root}
      title="Roxen Dashboard"
    >
      <Container maxWidth={false}>
        <Header startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
        <Grid
          container
          spacing={3}
        >
          {['admin','developer', 'customer_service'].includes(session.user.role_name) ? 
          <>
            <Grid
              item
              xs={12}
            >
              <Overview data={sumResult} loading={sumLoading}/>
            </Grid>
            <Grid
              item
              lg={8}
              xl={9}
              xs={12}
            >
              {/* <FinancialStats /> */}
              <PerformanceOverTime data={chartResult} loading={chartLoading}/>
            </Grid>
            <Grid
              item
              lg={4}
              xl={3}
              xs={12}
            >
              <EarningsSegmentation data={sumResult} loading={sumLoading}/>
            </Grid>
            {/* <Grid
              item
              lg={12}
              xs={12}
            >
              <EventsHistoryListTable eventsHistory={eventsResult} StatusTranslate={StatusTranslate} loading={eventsLoading} />
            </Grid> */}
          </>
          :
          <>
            <Grid
              item
              lg={8}
              xs={12}
            >
              {/* <LatestOrders /> */}
            </Grid>
            <Grid
              item
              lg={4}
              xs={12}
            >
              {/* <CustomerActivity /> */}
            </Grid>
            <Grid
              item
              lg={8}
              xs={12}
            >
              {/* <MostProfitableProducts /> */}
            </Grid>
            <Grid
              item
              lg={4}
              xs={12}
            >
              {/* <TopReferrals /> */}
            </Grid>
          </>}
        </Grid>
      </Container>
    </Page>
  );
}

export default DashboardAnalytics;
