import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SmartTable from '../../components/SmartTable'
import moment from 'moment';
import ImgDialog from '../../components/ImgDialog'
import axios from 'axios'
import { makeStyles } from '@material-ui/styles';
import LinkIcon from "@material-ui/icons/Link";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  IconButton,
  Card
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  }
}));

function HistoryListTable({ className, eventsHistory,StatusTranslate, loading, ...rest }) {
  const classes = useStyles();

  const columns = [
    {
      name: 'link_img',
      label: 'תמונה',
      options: {
        print:true,
        filter: false,
        sort: true,
        searchable: false,
        display: true,
        customBodyRender: (value, tableMeta ) => {
          let model=tableMeta.rowData[1];
          return <ImgDialog  dialogTitle={model} imgSrc={value} />;
        }
      }
    },
    {
      name: 'product_name',
      label: 'שם מוצר',
      options: {
        customBodyRender: (value) => {
          return <p style={{fontWeight:'bold'}}>{value}</p>
        }
      }
    },
    {
      name: '_billing_first_name',
      label: 'שם',
    },
    {
      name: '_billing_last_name',
      label: 'שם משפחה',
    },
    {
      name: '_billing_email',
      label: 'מייל',
    },
    {
      name: 'order_id',
      label: 'מס׳ הזמנה',
    },
    {
      name: 'item_status',
      label: 'סטטוס',
      options: {
        customBodyRender: (value) => {
          return StatusTranslate[value]
        }
      }
    },
    {
      name: 'order_item_id',
      label: 'מספר פריט בהזמנה',
    },
    {
      name: 'event_status',
      label: 'סטטוס שליחה', 
    },

    {
      name: 'template_name',
      label: 'טמפלייט לשליחה',
    },
    {
      name: 'created_at',
      label: 'הוספה',
      options:{
        customBodyRender: (value, tableMeta, updateValue) => {
          
          return moment(value).format('DD-MM-YYYY HH:mm:ss')
        }
      }
    },
    {
      name: 'updated_at',
      label: 'עדכון',
      options:{
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format('DD-MM-YYYY HH:mm:ss')
        }
      }
    },
    {
      name: "order_id",
      label: "לינק",
      options: {
          responsive: "stacked",
          filter: false,
          sort: false,
          searchable: false,
          download: false,
          customBodyRender: (value) => {
            return (
              <IconButton
                style={{textAlign:'left'}}
                aria-label="go to product page"
                color="primary"
                href={`https://shoesonline.co.il/wp-admin/post.php?post=${value}&action=edit`}
                target="_blank"
              >
                <LinkIcon />
              </IconButton>
            );
          },
        },
      },
  ];


  return (
    <Card
      className={clsx(classes.root, className)}
    >
      { eventsHistory.length > 0 && !loading && <SmartTable
        columns={columns}
        csvName={'היסטוריית שליחות לרימרקטי'}
        data={eventsHistory}
        selectableRows={false}
        title={''}
        addTotalRow={false}
      />}
      {loading &&
      <Skeleton
        variant="rect"
        animation="wave"
        height={400}
      />}
      
    </Card>
  );
}

HistoryListTable.propTypes = {
  className: PropTypes.string,
  eventsHistory: PropTypes.array
};

HistoryListTable.defaultProps = {
  eventsHistory: []
};

export default HistoryListTable;
