import React, { useState } from 'react';

export const CartContextProvider = React.createContext([]);
export const CartContext = ({children}) => {
  const [cart,setCart] = useState([]);
  return (
      <CartContextProvider.Provider value={[cart,setCart]}>
          {children}
      </CartContextProvider.Provider> 
  );
}