/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import { colors } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import StorefrontIcon from '@material-ui/icons/Storefront';
import AppsIcon from '@material-ui/icons/Apps';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import PostAddIcon from '@material-ui/icons/PostAdd';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import MaiIcon from '@material-ui/icons/MailOutline';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewConfigIcon from '@material-ui/icons/ViewComfy';
import ListIcon from '@material-ui/icons/List';
import EditIcon from '@material-ui/icons/Edit';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import TuneIcon from '@material-ui/icons/Tune';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WarningIcon from '@material-ui/icons/Warning';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import Label from 'src/components/Label';
import SearchIcon from '@material-ui/icons/Search';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
import MoveToInbox from '@material-ui/icons/MoveToInbox';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import WidgetsIcon from '@material-ui/icons/Widgets';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
export default [
  {
    subheader: 'Pages',
    items: [
      {
        roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager', 'developer', 'finance', 'trade','ppc'],
        title: 'דשבורד',
        href: '/dashboard',
        icon: HomeIcon
      },
      // {
      //   roll: ['developer', 'admin'],
      //   title: 'Dashboards',
      //   href: '/dashboards',
      //   icon: DashboardIcon,
      //   items: [
      //     {
      //       roll: ['admin', 'developer'],
      //       title: 'Default',
      //       href: '/dashboards/default'
      //     },
      //     {
      //       roll: ['admin', 'developer'],
      //       title: 'Analytics',
      //       href: '/dashboards/analytics'
      //     }
      //   ]
      // },

      {
        roll: ['admin', 'customer_service','content'  ,'customer_service_manager', 'developer', 'finance', 'trade','ppc'],
        title: 'דוחות',
        href: '/profitability',
        icon: ReceiptIcon,
        items: [
          {
            roll: ['developer', 'admin', 'customer_service'  ,'customer_service_manager', 'trade','ppc'],
            title: 'דוח ביטולים',
            href: '/profitability/CancellationsReport'
          },
          {
            roll: ['developer', 'admin', 'customer_service'  ,'customer_service_manager', 'trade','ppc'],
            title: 'דוח מחוץ למלאי',
            href: '/profitability/OutOfStockReport'
          },
          {
            roll: ['developer', 'admin', 'finance', 'trade','ppc'],
            title: 'רווחיות',
            href: '/profitability'
          },
          {
            roll: ['developer', 'admin', 'finance', 'trade','ppc'],
            title: 'דוח ספקים',
            href: '/profitability/suppliers_report'
          },
          {
            roll: ['admin','developer'],
            title: 'דוח וואצרים',
            href: '/profitability/VouchersReport'
          },
          {
            roll: ['developer', 'admin', 'finance','ppc','content', 'customer_service','hr'],
            title: 'דוח קופונים',
            href: '/profitability/CouponReport'
          }
          , {
            roll: ['developer', 'admin', 'trade' ,'customer_service_manager','ppc','finance'],
            title: 'דוח פרטי אספקה',
            href: '/profitability/supply_sales_report'
          }
          , {
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer','ppc'],
            title: 'דוח מוצרים שבוטלו',
            href: '/profitability/canceled_products'
          },{
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer','ppc'],
            title: 'דוח מועדון לקוחות',
            href: '/profitability/customer_club'
          },
          {
            roll: ['admin', 'customer_service','content'  ,'customer_service_manager', 'developer'],
            title: 'דוח פערי מחיר',
            href: '/profitability/cost_Gaps'
          },
          {
            roll: ['developer', 'admin', 'finance','warehouse'],
            title: 'דוח החזרות לספקים',
            href: '/profitability/supplierRefund'
          },
        ]
      },
      {
        roll: ['developer'],
        title: 'דוח זמני שילוח',
        href: '/delivery_report',
        icon: DirectionsCarIcon,
        items: [
          {
            roll: ['developer', 'admin', 'finance', 'trade'],
            title: 'הזמנות בתהליך',
            href: '/delivery_report/open'
          },
          {
            roll: ['developer', 'admin', 'finance', 'trade'],
            title: 'הזמנות סגורות',
            href: '/delivery_report/close'
          },
        ]
      },
      {
        roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer', 'trade'],
        title: 'תפעול',
        href: '/pick_up',
        icon: TuneIcon,
        items: [
          {
            roll: ['developer', 'admin'],
            title: 'פיקאפ',
            href: '/pick_up'
          },
          {
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer', 'trade'],
            title: 'סטטוסים לסינון',
            href: '/filter-statuses'
          },
          {
            roll: ['admin', 'developer'],
            title: 'עריכת סטטוסי הזמנות',
            href: '/edit_order_statuses',
          },
          {
            roll: ['admin', 'developer'],
            title: 'היסטוריית סטטוסי אספקה',
            href: '/suppliers_status_history',
          },
          {
            roll: ['admin', 'developer'],
            title: 'הזמנות מספקי חול שתקועים',
            href: '/supply_items_open',
          },
        ]
      },
      {
        roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager', 'developer', 'warehouse', 'trade', 'instagram','ppc'],
        title: 'ניהול מלאי מחסן',
        href: '/warehouse_inventory',
        icon: ListAltIcon,
        items: [
          {
            roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager', 'developer', 'warehouse', 'trade', 'instagram','ppc'],
            title: 'מוצרים במחסן',
            href: '/warehouse_inventory',

          },
          {
            roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager', 'developer', 'warehouse', 'trade', 'instagram','ppc'],
            title: 'מידות במחסן',
            href: '/warehouse_inventory/item_size_data',

          },
          // {
          //   roll:['admin', 'content', 'customer_service'  ,'customer_service_manager' ,'developer','warehouse'],
          //   title: 'הכנסה',
          //   href: '/warehouse_inventory/add_products',
          //   icon: KeyboardReturnIcon
          // },
          {
            roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager', 'developer', 'warehouse','ppc'],
            title: 'הוצאה',
            href: '/warehouse_inventory/remove_item_stock',
            icon: CallMissedOutgoingIcon
          },
          {
            roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager', 'developer', 'warehouse','ppc'],
            title: 'עריכת מלאי ספק',
            href: '/Storage',
            icon: MoveToInbox
          },
          // {
          //   roll: ['admin',  'developer','warehouse'],
          //   title: 'ספירת מלאי עריכת מלאי ספק',
          //   href: '/Storage2',
          //   icon: WidgetsIcon
          // },
          
          {
            roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager', 'developer', 'warehouse','ppc'],
            title: 'הוצאת כמות מהמלאי',
            href: '/warehouse_inventory/remove_item_stock_balk',
            icon: DeleteSweepIcon
          },
          {
            roll: [ 'content', 'developer', 'admin', 'warehouse', 'trade','ppc'],
            title: 'חיפוש מלאי',
            href: '/stock_meneger',
            icon: ImageSearchIcon,
          },
          {
            roll: ['developer', 'admin', 'warehouse'],
            title: 'הכנסה למלאי חדש',
            href: '/warehouse_inventory/manage_warehouse',
            icon: FilterCenterFocusIcon,
          },
          {
            roll: ['developer', 'admin', 'warehouse'],
            title: 'לוג מחסן',
            href: '/warehouse_inventory/warehouse_logs',
            icon: MenuOpenIcon,
            items: [
              {
                roll: ['developer', 'admin', 'warehouse'],
                title: 'חיפוש לפי תאריך',
                href: '/warehouse_inventory/warehouse_logs/date',
              },
              {
                roll: ['developer', 'admin', 'warehouse'],
                title: 'חיפוש לפי מקט',
                href: '/warehouse_inventory/warehouse_logs/sku',
              },
            ]
          }
        ]
      },
      {
        roll: ['developer', 'admin'],
        title: 'משלוחים',
        href: '/international_shipping',
        icon: LocalShippingIcon,
        items: [
          {
            roll: ['developer', 'admin'],
            title: 'משלוחים בינלאומיים',
            href: '/international_shipping'
          },
          {
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
            title: 'סיכום משלוחים',
            href: '/delivery_summary'
          },
        ]
      },
      {
        roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
        title: 'הזמנות בעייתיות',
        href: '/stolen_card',
        icon: ErrorOutlineIcon,
        items: [
          {
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
            title: 'כרטיסים גנובים',
            href: '/stolen_card'
          },
          {
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
            title: 'גנבים',
            href: '/thieves'
          },
          {
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
            title: 'חשודים',
            href: '/suspect_customer'
          },
          {
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
            title: 'מכחישי עסקה',
            href: '/denies'
          },
        ]
      },
      {
        roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
        title: 'פניות שירות',
        href: '/service_representatives',
        icon: ContactMailIcon,
        items: [
          {
            roll: ['admin', 'developer', 'customer_service'  ,'customer_service_manager'],
            title: 'פניות מנהל',
            href: '/service_representatives/manager/פתוח'
          },
          {
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
            title: 'סיכום פניות',
            href: '/service_representatives/total'
          },
          {
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
            title: 'פניות בטיפול',
            href: '/service_representatives/processing/מעקב',
            items: [
              {
                roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
                title: 'פניות במעקב',
                href: '/service_representatives/processing/מעקב'
              },
              {
                roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
                title: 'פניות פתוחות',
                href: '/service_representatives/processing/פתוח'
              }
              , {
                roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
                title: 'פניות סגורות',
                href: '/service_representatives/processing/סגור'
              }
              , {
                roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
                title: 'חיפוש פניות',
                href: '/service_representatives/processing/search'
              },
            ]
          }
        ]
      },
      {
        roll: ['developer', 'admin'  ,'customer_service_manager','hr','ppc'],
        title: 'פעולות שירות',
        href: '/ServiceActions',
        icon:AccessibilityIcon
      },
      {
        roll: ['developer', 'admin', 'customer_service'  ,'customer_service_manager'],
        title: 'פניות לקוח',
        href: '/leads',
        icon: AllInboxIcon,
      },
      {
        roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
        title: 'שליחת איוונטים',
        href: '/events/list',
        icon: MaiIcon,
        items: [
          {
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
            title: 'רשימה לשליחה',
            href: '/events/list'
          },
          {
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
            title: 'היסטוריית שליחות',
            href: '/events/history'
          },
          {
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
            title: 'שיחרור ממתינה',
            href: '/events/delay_cancel_events'
          },
          {
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
            title: 'איוונטים ממתינים',
            href: '/events/waiting_list'
          },
          {
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
            title: 'עריכת איוונטים ממתינים',
            href: '/events/edit_waiting_list'
          },
          {
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
            title: 'שליחת חופשית',
            href: '/events/send_free_events',
            label: () => <Label color={colors.blue['500']}>beta</Label>
          },
        ]
      },
      {
        roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer','finance', 'warehouse','hr'],
        title: 'החזרות',
        href: '/return_item_stock/search',
        icon: AssignmentReturnIcon,
        items: [
          {
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer', 'warehouse'],
            title: 'חיפוש החזרות',
            href: '/return_item_stock/search',
          },
          {
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer', 'warehouse'],
            title: 'כל ההחזרות',
            href: '/return_item_stock/all',
          },
          {
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer', 'warehouse','hr'],
            title: 'החזרת לקוחות',
            href: '/SuppliersReturn',
          },
          {
            roll: ['admin', 'customer_service'  ,'customer_service_manager','finance', 'developer', 'warehouse','hr'],
            title: 'החזרות לספקים',
            href: '/SuppliersReturn/TempReturnPage',
          }
        ]
      },
      {
        roll: ['admin', 'developer', 'warehouse'],
        title: 'תקינים',
        href: '/return_valid_items',
        icon: ThumbUpIcon,
      },
      {
        roll: ['admin', 'content',  'developer', 'warehouse','ppc'],
        title: ' פעולות על מוצרים',
        href: '/ActionOnProduct',
        icon: EditIcon
      },
      {
        roll: ['admin', 'content',  'developer', 'warehouse','ppc'],
        title: 'פיד מוצרים',
        href: '/MsFeed',
        icon: SearchIcon
      },
      {
        roll: ['admin', 'content', 'developer', 'warehouse'],
        title: 'עדכון מלאי',
        href: '/ActionOnFile',
        icon: PostAddIcon
      },
      {
        roll: ['admin', 'content', 'developer', 'warehouse'],
        title: 'קובץ להעלאת מוצרים',
        href: '/ViewFile',
        icon: VisibilityIcon
      },
      {
        roll: ['admin', 'content', 'customer_service'  ,'customer_service_manager', 'developer', 'warehouse','ppc'],
        title: 'חיפוש מוצרים',
        href: '/search_product',
        icon: SearchIcon,
        // items:  {
        //   roll: ['admin', 'content', 'customer_service', 'developer', 'warehouse'],
        //   title: 'חיפוש מוצרים',
        //   href: '/search_product',
        // },

      },
      {
        roll: ['admin', 'content', 'trade', 'developer', 'warehouse','ppc'],
        title: 'כל המוצרים',
        href: '/all_products',
        icon: AppsIcon,
      },
      {
        roll: ['admin', 'content', 'developer','ppc'],
        title: 'לוגים פרויקט 2 ספקים',
        href: '/two_suppliers_logs_item',
        icon: FormatAlignJustifyIcon,
        items: [
          {
            roll: ['admin', 'content', 'developer','ppc'],
            title: 'לוג פריטים',
            href: '/two_suppliers_logs_item',
          },
          {
            roll: ['admin', 'content', 'developer','ppc'],
            title: 'לוג ווריאציות',
            href: '/two_suppliers_logs_variations',
          },
          {
            roll: ['admin', 'content', 'developer','ppc'],
            title: 'לוג עדכון מידות ',
            href: '/two_suppliers_logs_new_sizes',
          },
        ]
      },
      {
        roll: ['admin', 'developer'],
        title: 'שגיאות תשלום',
        href: '/ipn_errors',
        icon: WarningIcon,
      },
      {
        roll: ['admin', 'developer', 'customer_service'  ,'customer_service_manager'],
        title: 'פעולות ארנק',
        href: '/wallet_log',
        icon: AccountBalanceWalletIcon,
      },
      // {
      //   roll: ['admin', 'developer'],
      //   title: 'חנות ספקים',
      //   href: '/supplier_store',
      //   icon: StorefrontIcon,
      // },
      {
        roll: ['developer', 'admin'],
        title: 'היסטוריית שאילתות',
        href: '/users_history',
        icon: AccountTreeIcon,
      },
      {
        roll: ['developer', 'admin' , 'content','ppc'],
        title: 'ניהול חיפושים באתר',
        href: '/handle_searches',
        icon: FindInPageIcon,
      },
      {
        roll: ['developer', 'admin','customer_service_manager'],
        title: 'הרשמה',
        href: '/admin_tools/sign_up',
        icon: AssignmentIndIcon,
      },
      {
        roll: ['developer', 'admin', 'content', 'customer_service'  ,'customer_service_manager','ppc'],
        title: 'פעולות ידניות',
        href: '/admin_tools/manually_events',
        icon: OfflineBoltIcon,
      },
      {
        roll: ['developer', 'admin'],
        title: 'תשלומים',
        href: '/payments',
        icon: AttachMoneyIcon,
        items: [
          {
            roll: ['developer', 'admin'],
            title: 'הסטוריית עסקאות',
            href: '/payments'
          },
          {
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
            title: 'תשלומים / חיובים',
            href: '/payments/payouts'
          },
          {
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
            title: 'ריכוז כרטסות',
            href: '/payments/sum_deals'
          },

        ]
      },
      {
        roll: ['developer', 'customer_service'  ,'customer_service_manager', 'admin'],
        title: 'עריכת מידע הזמנות',
        href: '/update_users_info',
        icon: EditIcon,
        items: [
          {
            roll: ['developer', 'customer_service'  ,'customer_service_manager', 'admin'],
            title: 'עריכת פלאפונים',
            href: '/update_users_info/phone_numbers'
          },
          {
            roll: ['admin', 'customer_service'  ,'customer_service_manager', 'developer'],
            title: 'עריכת כתובת',
            href: '/update_users_info/address'
          },
        ]
      },









  //     {
  //       roll: ['developer'],
  //       title: 'Management',
  //       href: '/management',
  //       icon: BarChartIcon,
  //       items: [
  //         {
  //           roll: ['developer'],
  //           title: 'Customers',
  //           href: '/management/customers'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Customer Details',
  //           href: '/management/customers/1/summary'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Projects',
  //           href: '/management/projects'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Orders',
  //           href: '/management/orders'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Order Details',
  //           href: '/management/orders/1'
  //         }
  //       ]
  //     },
  //     {
  //       roll: ['developer'],
  //       title: 'Social Feed',
  //       href: '/social-feed',
  //       icon: PeopleIcon
  //     },
  //     {
  //       roll: ['developer'],
  //       title: 'Profile',
  //       href: '/profile',
  //       icon: PersonIcon,
  //       items: [
  //         {
  //           roll: ['developer'],
  //           title: 'Timeline',
  //           href: '/profile/1/timeline'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Connections',
  //           href: '/profile/1/connections'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Projects',
  //           href: '/profile/1/projects'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Reviews',
  //           href: '/profile/1/reviews'
  //         }
  //       ]
  //     },
  //     {
  //       roll: ['developer'],
  //       title: 'Project',
  //       href: '/projects',
  //       icon: FolderIcon,
  //       items: [
  //         {
  //           roll: ['developer'],
  //           title: 'Browse',
  //           href: '/projects'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Create',
  //           href: '/projects/create'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Overview',
  //           href: '/projects/1/overview'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Files',
  //           href: '/projects/1/files'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Activity',
  //           href: '/projects/1/activity'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Subscribers',
  //           href: '/projects/1/subscribers'
  //         }
  //       ]
  //     },
  //     {
  //       roll: ['developer'],
  //       title: 'Invoice',
  //       href: '/invoices/1',
  //       icon: ReceiptIcon
  //     },
  //     {
  //       roll: ['developer'],
  //       title: 'Kanban Board',
  //       href: '/kanban-board',
  //       icon: ListAltIcon
  //     },
  //     {
  //       roll: ['developer'],
  //       title: 'Mail',
  //       href: '/mail',
  //       icon: MailIcon,
  //       label: () => (
  //         <Label
  //           color={colors.red[500]}
  //           shape="rounded"
  //         >
  //           2
  //         </Label>
  //       )
  //     },
  //     {
  //       roll: ['developer'],
  //       title: 'Chat',
  //       href: '/chat',
  //       icon: ChatIcon,
  //       label: () => (
  //         <Label
  //           color={colors.red[500]}
  //           shape="rounded"
  //         >
  //           4
  //         </Label>
  //       )
  //     },
  //     {
  //       roll: ['developer'],
  //       title: 'Calendar',
  //       href: '/calendar',
  //       icon: CalendarTodayIcon,
  //       label: () => <Label color={colors.green[500]}>New</Label>
  //     },
  //     {
  //       roll: ['developer'],
  //       title: 'Settings',
  //       href: '/settings',
  //       icon: SettingsIcon,
  //       items: [
  //         {
  //           roll: ['developer'],
  //           title: 'General',
  //           href: '/settings/general'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Subscription',
  //           href: '/settings/subscription'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Notifications',
  //           href: '/settings/notifications'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Security',
  //           href: '/settings/security'
  //         }
  //       ]
  //     },
  //     {
  //       roll: ['developer'],
  //       title: 'Authentication',
  //       href: '/auth',
  //       icon: LockOpenIcon,
  //       items: [
  //         {
  //           roll: ['developer'],
  //           title: 'Login',
  //           href: '/auth/login'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Register',
  //           href: '/auth/register'
  //         }
  //       ]
  //     },
  //     {
  //       roll: ['developer'],
  //       title: 'Errors',
  //       href: '/errors',
  //       icon: ErrorIcon,
  //       items: [
  //         {
  //           roll: ['developer'],
  //           title: 'Error 401',
  //           href: '/errors/error-401'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Error 404',
  //           href: '/errors/error-404'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Error 500',
  //           href: '/errors/error-500'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   // subheader: 'Support',
  //   items: [
  //     {
  //       roll: ['developer'],
  //       title: 'Components',
  //       href: '/components',
  //       icon: ViewConfigIcon,
  //       items: [
  //         {
  //           roll: ['developer'],
  //           title: 'Buttons',
  //           href: '/components/buttons'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Cards',
  //           href: '/components/cards'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Chips',
  //           href: '/components/chips'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Lists',
  //           href: '/components/lists'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Forms',
  //           href: '/components/forms'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Modals',
  //           href: '/components/modals'
  //         },
  //         {
  //           roll: ['developer'],
  //           title: 'Typography',
  //           href: '/components/typography'
  //         }
  //       ]
  //     },
  //     {
  //       roll: ['developer'],
  //       title: 'Presentation',
  //       href: '/presentation',
  //       icon: PresentToAllIcon
  //     },
  //     {
  //       roll: ['developer'],
  //       title: 'Getting started',
  //       href: '/getting-started',
  //       icon: CodeIcon
  //     },
  //     {
  //       roll: ['developer'],
  //       title: 'Changelog',
  //       href: '/changelog',
  //       icon: ListIcon,
  //       label: () => <Label color={colors.blue['500']}>v1.3.0</Label>
  //     }
    ]
  }
];
