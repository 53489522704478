import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { setLocalUser , getSuppliers, getColors, checkToken } from 'src/actions';
import { useDispatch } from 'react-redux';
// Example of user roles: ['GUEST', 'USER', 'ADMIN'];

// function AuthGuard({ roles, children }) {
function AuthGuard({routes, history }) {
  const session = useSelector((state) => state.session);
  const colors = useSelector((state) => state.suppliers);
  const dispatch = useDispatch();
  useEffect( () => {
    var userItem = localStorage.getItem('userLogd')

    if(session.access_token && session.loggedIn){
        dispatch(checkToken(session.access_token, history))
    }
    const getStaticData = async (event) => { 
        await dispatch(getColors(session.access_token))

        await dispatch(getSuppliers())

    };

    if((colors.colors_arr.length | colors.suppliers_arr.length) == 0){
      getStaticData()
    }
    


    if (!session.loggedIn) {
      if(userItem){ 
        dispatch(setLocalUser( JSON.parse(userItem)))
        return;
      }else{
        history.push('/auth/login');
        return;
      }

    }
    // if (!roles.includes(session.user.role)) {
    //   history.push('/errors/error-401');
    // }

    routes[4]['routes'] = routes[4]['routes'].filter((el) => {
      if(el.roll){
        return el.roll.includes(session.user.role_name);
      }else{
        return true
      }
    });

  }, [history, session.loggedIn, session]);
  
  // useEffect( () => {
  //   routes[4]['routes'] = routes[4]['routes'].filter((el) => {
  //     if(el.roll){
  //       return el.roll.includes(session.user.role_name);
  //     }
  //   });
  // }, [history, session.loggedIn, session])

  return <>{renderRoutes(routes)}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
  // roles: PropTypes.array.isRequired
};

export default AuthGuard ;
