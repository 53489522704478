import * as actionTypes from 'src/actions';

const initialState = {
  loggedIn: false,
  loginError: null,
  user: {
    // id: '',
    // email: "",
    // first_name: "",
    // last_name: "",
    // role: '',
    // role_title: "",
    // role_name: ""
    // first_name: '',
    // last_name: '',
    // email: '',
    // avatar: '',
    // bio: '',
    // role: '' // ['GUEST', 'USER', 'ADMIN']
  }
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      return {
        ...state,
        loggedIn: action.payload.loginError != null ? false : true ,
        loginError: action.payload.loginError ,
        user: {
          ...action.payload.user
        },
        access_token: action.payload.access_token,
        
      } 
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        user: {
          // role: 'GUEST'
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
