import React, {useContext} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Fab from '@material-ui/core/Fab';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MailIcon from '@material-ui/icons/Mail';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { CartContextProvider } from './CartContext';

const useStyles = makeStyles(theme => ({
  list: {
    width: 350,
  },
  fullList: {
    width: 'auto',
  },
  img:{
    width: '85px',
    textAlign: 'center',
  },
  empty: {
    textAlign: 'center',
    height: '20vh',
    margin: '0 auto',
    padding: '46vh 0',
    alignItems: 'center'
    
  },
  drawerHeader: {
    backgroundColor: "black" ,
    color: 'white',
  },
  listItem:{
    textAlign: 'right',
    padding: theme.spacing(1),
  },
  price:{
    padding: theme.spacing(5, 2),
    verticalAlign: 'middle'
  },
  fab: {
    // margin: theme.spacing(2), 
    padding: '5px',
    position: "fixed",
    width: '316px',
    backgroundColor: '#000',
    borderRadius: '38px 0px 0px 38px',
    bottom: theme.spacing(2),
    boxShadow: '0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12)',
    // right: theme.spacing(3),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

Object.size = function(obj) {
  var size = 0, key;
  for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
  }
  return size;
};

export default function Cart({open, setOpen}) {
  const classes = useStyles();
  const theme = useTheme();
  const [cart,setCart] = useContext(CartContextProvider)
  // const [sumCart, setSumCart] = React.useState(0);
  var sumCart = 0;

  const handleIncrement = (key) => {

    let product = {
      cart_qty: ( (cart[key] && cart[key].cart_qty) || 0) + 1 <= cart[key].info.item_qty_for_sell ? ( (cart[key].info.item_id && cart[key].cart_qty) || 0) + 1 : cart[key].info.item_qty_for_sell,
      info: cart[key].info
    }
    setCart({...cart,  [key]: product });
  };
  
  const handleDecrement = (key) => {

    let product = {
      cart_qty: ( (cart[key] && cart[key].cart_qty) || 0) - 1 >= 0  ? ( (cart[key].info.item_id && cart[key].cart_qty) || 0) - 1 : 0,
      info: cart[key].info
    }
    setCart({...cart,  [key]: product });
    
  };
  const toggleDrawer = (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(!open);
  };

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      // onClick={toggleDrawer}
      // onKeyDown={toggleDrawer}
    >
      <Grid 
        container   
        justify="space-between"
        alignItems="center" 
        className={classes.drawerHeader}
      >
        <Grid item>
          <Typography style={{paddingLeft:'20px', color:'white'}} variant="h5" display="block">
            {`${Object.size(cart) || 0} פריטים בעגלה`}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton style={{color:'white'}} onClick={toggleDrawer}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Grid>
      </Grid>

      <Divider />
      <List>
        {Object.size(cart) > 0 ? Object.keys(cart).map((key, index) => {
          sumCart += cart[key].info.sale_price * cart[key].cart_qty
          return (
          <div key={cart[key].info.item_id} >
            <ListItem alignItems="flex-start" key={cart[key].info.item_id} className={classes.listItem}>
              <Grid 
                container   
                justify="space-between"
                alignItems="center"
              >
                  <Grid item xs={1}>
                    <ButtonGroup   orientation="vertical" size="small" >
                      <Button style={{ minWidth: '20px', padding: 0}} disabled={cart[key].cart_qty === cart[key].item_qty_for_sell} onClick={()=>handleIncrement(key)}>+</Button>
                      <Button style={{ minWidth: '20px', padding: 0}} disabled>{cart[key].cart_qty}</Button>
                      <Button style={{ minWidth: '20px', padding: 0}} disabled={cart[key].cart_qty === 0} onClick={()=>handleDecrement(key)}>-</Button>
                    </ButtonGroup>
                  </Grid>
                  <Grid item xs={3} className={classes.img}>
                    <img alt={cart[key].info.product_name} src={cart[key].info.link_img} width='auto' height='85px' />
                  </Grid>
                  <Grid item xs={6} style={{padding: '0 5px'}}>
                    <Typography variant="button" display="block" >
                    {cart[key].info.product_name}
                    </Typography>
                    <Typography variant="overline" display="block" >
                    {`מידה: ${cart[key].info.item_size}`}
                    </Typography>
                    <Typography variant="h6" 
                      color="primary"
                      
                    >
                      {`${cart[key].cart_qty} X ${cart[key].info.sale_price}₪`} 
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h6" 
                      // className={classes.price}
                      
                    >
                      {`${cart[key].info.sale_price * cart[key].cart_qty}₪`} 
                    </Typography>
                  </Grid>
              </Grid>
            </ListItem>
            {index + 1 < Object.size(cart) && <Divider component="li" />}
          </div>
          
        )})
        :<div className={classes.empty}>
          <Typography variant="h6" 
            className={classes.price}
            
          >
            סל הקניות שלך ריק
          </Typography>
        </div>}
      </List>
      {/* <div className={classes.fab}> */}
      {sumCart > 0 && <Grid 
        container   
        justify="space-between"
        alignItems="center"
        className={classes.fab}
      >
        <Grid style={{textAlign:'center'}} item xs={5}>
          <Typography style={{color:'white'}} variant="overline" gutterBottom>
            {`סה״כ לתשלום`}
          </Typography>
          <Typography style={{color:'white'}} variant="h5" gutterBottom>
            {`${sumCart} ₪`}
          </Typography>
        </Grid>
        <Grid item >
          <Fab disabled={sumCart === 0} color="primary" variant="extended">
            לסיום ההזמנה
            <SendIcon className={classes.extendedIcon} />
          </Fab>
        </Grid>
      </Grid>}
        {/* <Typography variant="h6" >
          {sumCart}
        </Typography>
        <Fab  variant="extended">
          לסיום ההזמנה
          <SendIcon className={classes.extendedIcon} />
        </Fab> */}
      {/* </div> */}
    </div>
  );

  return (
    <React.Fragment >
      <SwipeableDrawer
        anchor='right'
        open={open}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        {list()}
      </SwipeableDrawer>
    </React.Fragment>
  );
}
