export function getAllStatusTranslate() {
  return {
    'storage' : 'מחסן',
    'storage_need_pay' : 'אריזה -ממתין לתשלום' ,
    'packing' : 'אריזה',
    'packing_delay' : 'ארוז - מתעכב',
    'packing_waiting' : 'ארוז - ממתין לפריט נוסף',
    'ready_for_shipment' : 'מוכן למשלוח',
    'waiting_for_delivery' : 'ממתין לשליח',
    'supplier' : 'ספק',
    'supplier_processing' : 'בטיפול ספק',
    'ordered_from_supplier' : 'הוזמן מספק',
    'supplier_delay' : 'עיכוב מספק',
    'supplier_outofstock' : 'אין במלאי ספק',
    'supplier_ready_for_shipment' : 'ספק ממתין לשליח',
    'delivery_company_processing' : 'בטיפול חברת משלוחים',
    'supplier_in_packing' :'תהליך אריזה אצל הספק',
    'replacement_proceed' : 'בתהליך החלפה',
    'replacement_pickup' : 'איסוף החלפה',
    'replacement_pickup_delivery' : 'איסוף החלפה - במשלוח', 
    'replacement_not_ok' : 'החלפה לא תקינה',
    'replaced' : 'הוחלף',
    'refund_requested' : 'בקשה להחזרה', 
    'refund_proceed' : 'בתהליך החזרה',
    'refund_pickup' : 'איסוף החזרה',
    'refund_pickup_delivery' : 'איסוף החזרה - במשלוח', 
    'refund_ok' : 'החזרה תקינה',
    'refund_not_ok' : 'החזרה לא תקינה',
    'refunded' : 'הוחזר',
    'new' : 'חדש',
    'sent' : 'נשלח',
    'defected_pickup' : 'איסוף מוצר פגום',
    'defected_processing' : 'מוצר פגום בטיפולנו',
    'defected_supplier' : 'מוצר פגום בספק',
    'defected_cobbler' : 'מוצר פגום בסנדלר',
    'defected_handled' : 'מוצר פגום - טופל',
    'on_check': 'פריט בבדיקה',}
}

export function getSentStatus(){
  return ['sent','waiting_for_delivery', 'supplier_ready_for_shipment' ]
}
export function getRefundStatus(){
  return ['refunded','refund_pickup', 'refund_proceed', 'refund_pickup_delivery' ,'refund_ok' , 'refund_not_ok', 'refund_requested']
}
export function getReplacStatus(){
  return ['replaced','replacement_not_ok', 'replacement_pickup_delivery', 'replacement_pickup' ,'replacement_proceed' ]
}
export function getProcessStatus(){
  return [ 
    'storage',
    'packing',
    'packing_delay',
    'packing_waiting',
    'ready_for_shipment' ,
    'supplier' ,
    'supplier_processing' ,
    'ordered_from_supplier' ,
    'supplier_delay' ,
    'supplier_outofstock',
    'supplier_in_packing' ,
    'delivery_company_processing'  ]
}

export function getCheckStatus(){
  return ['on_check']
}

export function getSupply_statuses() {
 return{
  'open' : 'פתוח',
  'instock' : 'יש במלאי',
  'outofstock_processing' : 'אין במלאי - בטיפול',
  'delay' : 'מתעכב',
  'ready_for_shipment' : 'מוכן למשלוח',
  'delivery_company_processing' : 'בטיפול חברת משלוחים',
  'deliverycom_ready_for_shipment' : 'מוכן למשלוח בחברת שליחויות',
  'process_canceling_supply' : 'פריט אספקה בתהליך ביטול' ,
  'supplier_warehouse_processing' : 'בטיפול מחסן ספק' ,
  'supplier_warehouse_in_packing' : 'אריזה מחסן ספק' ,
  'supplier_independent_in_packing': 'אריזה ספק עצמאי' ,
  'supplier_warehouse_in_packing_waiting' : 'אריזה מחסן ספק- ממתין לפריט נוסף' ,
  'packing_return_from_canceled' : 'אריזה פריט שבוטל',
  'packed_in_warehouse' : 'אריזה במחסן',
  'moved_then_canceled' : 'פריט אספקה הוחלף ובוטל',
  'outofstock' : 'אין במלאי', //final
  'received' : 'נקלט', //final
  'returned' : 'הוחזר', // final
  'canceled_supply' : 'בוטל בסטטוס אספקה' //final
 }
}
